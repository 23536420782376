import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
  <div class="footer">
    <span class="created-by">
    © 2022, Powered by Ananta Futuristic Solutions Pvt. Ltd.
    Power Exchanger :P-P -V. 2.0
    </span>
    </div>
  `,
})
export class FooterComponent {
}
