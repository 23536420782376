<div class="background">

  <div class="formGroup">
    <form>
      <div [formGroup]="loginForm">

        <div class="check">


          <div class=" ca">
            <input class="form-check-input" type="radio" name="ca" id="ca" (change)="display($event)" value="CA">
            <label class="form-check-label" for="ca">
              <b> CA</b>
            </label>
          </div>
          <div class="client">
            <input class="form-check-input" type="radio" name="ca" id="client" (change)="display($event)"
              value="Client">
            <label class="form-check-label" for="client">
              <b>Client</b>
            </label>
          </div>

        </div>



        <br>
        <br><br>
        <div class="form-group row">
          <input type="password" class="fields" placeholder="CA Code" formControlName="caCode" id="caCode" />
        </div>




        <div class="form-group row">
          <input type="password" class="fields" placeholder="Client Code" formControlName="clientCode" id="clientCode"
            [hidden]="hideClient" />
        </div>



        <div class="form-group row">
          <input type="text" class="fields" placeholder="User Name" formControlName="loginId" id="userId" />
        </div>


        <div class="form-group row">
          <input type="password" class="fields" placeholder="Password" formControlName="password" id="password" />
        </div>

      </div>
    </form>
    <div class="form-group row">
      <small [hidden]="invalidData">
        <b> Invalid Credentials !!!</b></small>
    </div>
    <div class="col-md-6; buttons">
      <!-- <button type="submit" class="button" (click)="userLogin()"><b>Login</b> </button> -->
      <button class="btn  " (click)="userLogin()">Login</button>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <!-- <button type="submit" class="button" (click)="Register()"><b>Register</b> </button> -->
      <button class="btn " (click)="Register()">Register</button>

    </div>

    <div class="form-group row">
      <div class="col-md-4"></div>
      <div class="col-md-6">
        <button class="forgot" (click)="goToForgot()">Forgot Password?</button>
      </div>
    </div>

    <div class=" contact">
      <p> For Support and Technical Help
        <br> Please contact at <b>8860797403</b></p>
    </div>


  </div>




</div>








<div class="footer">
  <span class="created-by">
    © 2022, Developed by Ananata Futuristic Solutions Pvt. Ltd.( www.powererp.in ) V.2.0
  </span>
</div>